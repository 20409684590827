.saved-readings {
    -webkit-overflow-scrolling: touch;

    > h2 {
        text-align: center;
        margin-top: 42px;
    }

    .no-saved-msg {
        text-align: center;
    }

    .edit-btn, .trash-btn, .check-btn {
        background: none;
        padding: 0;
    }

    .button-container {
        margin: 31px 38px 0;
        overflow: hidden;

        .edit-btn {
            float: right;
        }

        .trash-btn {
            float: left;
            margin-left: 20px;

            &.disabled {
                opacity: .5;
            }
        }

    }

}

.saved-readings-list {
    margin: 0 38px 68px 38px;
    min-width: auto;
    text-align: left;
    padding-top: 20px;

    &.is-editing {
        > li {
            .check-btn {
                opacity: 1;
                visibility: visible;
                width: 32px;
            }

            .saved-question-wrapper {
                transform: translateX(15px);
            }
        }
    }

    > li {
        margin: 0 20px 40px 20px;
        cursor: pointer;
        display: flex;
        transition: transform .5s ease-in-out;
        max-height: 200px;

        transition: max-height .5s, margin-bottom .5s;

        &.remove {
            max-height: 0 !important;
            //padding: 0;
            overflow: hidden;
            margin-bottom: 0;
        }

        .saved-question-wrapper {
            transition: transform .5s ease-in-out;
            width: 100%;
            display: flex;
            flex-shrink: 0;
        }

        .check-btn {
            //margin-right: 20px;
            opacity: 0;
            visibility: hidden;
            width: 0;
            z-index: 50;
        }

        .saved-reading-data {
            display: inline-block;
            margin-left: 50px;
            position: relative;
            width: 100%;
            min-width: 0;
        }

        .question {
            max-width: 70%;

            h2 {
                text-overflow: ellipsis;
                word-wrap: break-word;
                white-space: nowrap;
                overflow: hidden;
                margin: 0 0 20px;
                line-height: 1.5;
            }
        }

        .date {
            font-size: 12px;
            position: absolute;
            top: 5px;
            right: 0;

            .short-date {
                display: none;
            }
        }

        .num, .title {
            display: inline-block;
            font-weight: 500;
        }

        .title {
            max-width: 70%;
            text-overflow: ellipsis;
            word-wrap: break-word;
            white-space: nowrap;
            overflow: hidden;
            vertical-align: bottom;
        }

        .static-hexagram {
            text-align: center;
            font-weight: 400;
            display: inline-block;
            align-self: center;

            .hexagram {
                display: inline-block;
                max-width: 100px;
                margin: 0 auto;

                li {
                    border-radius: 2px;
                    transition-property: none;
                    height: 8px;
                    margin: 8px 0;

                    &:before, &:after {
                        height: 8px;
                        border-radius: 2px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 960px) {
    .saved-readings-list {
        > li {
            .static-hexagram {
                .hexagram {
                    width: 70px;
                    margin: 0 auto;

                    li {
                        border-radius: 1px;
                        height: 5px;
                        margin: 5px 0;

                        &:before, &:after {
                            height: 5px;
                            border-radius: 1px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .saved-readings {
        h2 {
            margin-top: 21px;
        }

        .button-container {
            svg {
                width: 24px;
            }
        }
    }

    .saved-readings-list {
        > li {
            .date {
                .short-date {
                    display: block;
                }

                .full-date {
                    display: none;
                }
            }

            h2 {
                font-size: 18px;
            }

            .num, .title {
                font-size: 16px;
            }

            .check-btn svg {
                width: 24px;
            }

            .static-hexagram {
                .hexagram {
                    width: 50px;
                    margin: 0 auto;

                    li {
                        border-radius: 1px;
                        height: 3px;
                        margin: 3px 0;

                        &:before, &:after {
                            height: 3px;
                            border-radius: 1px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 599px) {
    .saved-readings {
        .button-container {
            margin-left: 24px;
            margin-right: 24px;
        }
    }

    .saved-readings-list {
        margin: 0 24px 68px 24px;
    }
}

@media only screen and (max-width: 499px) {
    .saved-readings {
        .button-container {
            margin-left: 19px;
            margin-right: 19px;
        }
    }

    .saved-readings-list {
        margin: 0 19px 34px 19px;

        > li {
            .date {
                font-size: 10px;
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    .saved-readings {
        .button-container {
            margin-left: 0;
            margin-right: 0;
        }
    }

    .saved-readings-list {
        > li {
            margin-left: 0;
            margin-right: 0;

            .static-hexagram {
                display: none;
            }

            .saved-reading-data {
                margin: 0;
            }
        }
    }
}
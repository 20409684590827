$coin-color: #FED477;
$coin-inner-color: #E9B259;
$coin-size: 110px;
$half-coin-size: $coin-size / 2;

@mixin coinSize($coinSize) {
    width: $coinSize;
    height: $coinSize;
}

.coinSize {
    @include coinSize($coin-size);
}

@mixin staticCoin($size, $side) {
    $borderSize: $size * 0.05;

    .coin {
        width: $size;
        height: $size;
        font-size: $size;

        .#{$side} {
            display: flex;
            align-items: center;
            width: $size;
            height: $size;
            box-shadow: inset 0 0 0 $borderSize - 2 #FED477, inset 0 0 0 $borderSize - 1 rgba($black, 0.1), 0 0 $borderSize rgba($black, 0.2);

            img {
                width: $size * 0.8;
                height: $size * 0.8;
                margin-top: if($side == 'front', $size * 0.09, 0);
            }

            &:after {
                width: $size;
                height: $size;
            }
        }
    }
}

.coin-area {
    opacity: 1;
    height: auto;
    transition: opacity 0.5s ease-in-out;
    &.hide-coins {
        opacity: 0;
        height: 0;
    }
}

.coins {
    span {
        position: absolute;
        color: white;
        top: 50%;
        left: 50%;
        text-align: center;
        transform: translate(-50%, -50%);
    }
}

.coin {
    display: inline-block;
    @extend .coinSize;
    margin: 15px;
    position: relative;
    perspective: 1000;
    border-radius: 50%;
    top: 0;

    &.coin2 {
        animation-delay: 0.25s;
    }

    &.coin3 {
        animation-delay: 0.5s;
    }

    &.flipping {
        @extend .flipping;
        @include animatedFor(0.5s);
    }

    // React Transition Classes
    &.coin-enter {
        opacity: 0;
    }

    &.coin-enter.coin-enter-active {
        @extend .coinDrop; // Coin bounce drop animation
        @include animatedFor(1.5s);
    }

    &.coin-exit {
        opacity: 1;
    }

    &.coin-exit.coin-exit-active {
        opacity: 0;
    }
}

// flip speed goes here
.flipper {
    transition: 0.6s;
    transform-style: preserve-3d;
    transform-origin: 100% half-coin-size;
    position: relative;
    border-radius: 50%;
    &.tails {
        .front {
            transform: rotateX(-180deg);
        }
        .back {
            transform: rotateY(0deg);
        }
    }

}

// hide back of pane during swap
.front,
.back {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    @extend .coinSize;
    border-radius: 50%;
    background-color: $coin-inner-color;
    box-shadow: inset 0 0 0 8px #FED477, inset 0 0 0 9px rgba($black, 0.1), 0 0 10px rgba($black, 0.2);
    transition: 0.6s;

    img {
        width: 75px;
        display: inline-block;
        margin: 19px auto 0;
        filter: drop-shadow(1px 1px 0 rgba($black, 0.2));
    }
}


.coinShine {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    @extend .coinSize;
    background-image: url(./../shared/images/coin-shine.png);
    background-size: 100% 200%;
    background-repeat: no-repeat;
    animation: moveShine 5s infinite;
    border-radius: 50%;
    z-index: 3;
}


// heads pane, placed above tails
.front {
    z-index: 2;
    transform: rotateX(0deg); // for firefox 31
    &:after {
        @extend .coinShine;
    }
}


// tails, initially hidden pane
.back {
    transform: rotateX(180deg);
    &:after {
        @extend .coinShine;
    }

    img {
        margin-top: 15px;
    }
}


.toss-btn, .reset-btn {
    margin-bottom: 40px;
}

.reset-btn {
    margin-top: 10px;
}

.toss-btn {
    @extend .fadeIn;
    @include animatedFor(0.5s);
}


@media only screen and (max-width: 500px) {
    $coin-size: 76px;
    $half-coin-size: 38px;

    @mixin smallCoinSize {
        @include coinSize($coin-size);
    }

    .coin {
        @include smallCoinSize;
    }

    .flipper {
        transform-origin: 100% $half-coin-size;
    }

    .front, .back {
        @include smallCoinSize;
        box-shadow: inset 0 0 0 5px #FED477, inset 0 0 0 6px rgba($black, 0.1), 0 0 5px rgba($black, 0.2);
        img {
            width: 54px;
            margin: 12px auto 0;
        }
    }

    .front {
        &:after {
            @include smallCoinSize;
        }
    }

    .back {
        &:after {
            @include smallCoinSize;
        }
        img {
            margin-top: 9px;
        }
    }
}

@media only screen and (max-width: 360px) {
    .coin {
        margin: 10px;
    }
}

@media only screen and (max-width: 320px) {
    .toss-btn, .reset-btn {
        margin-bottom: 20px;
    }
}

@media only screen and (min-width: 480px) and (max-width: 800px) and (orientation: landscape) {
    $coin-size: 76px;
    $half-coin-size: 38px;

    @mixin smallCoinSize {
        @include coinSize($coin-size);
    }

    .coin {
        @include smallCoinSize;
        margin: 10px;
    }

    .flipper {
        transform-origin: 100% $half-coin-size;
    }

    .front, .back {
        @include smallCoinSize;
        box-shadow: inset 0 0 0 5px #FED477, inset 0 0 0 6px rgba($black, 0.1), 0 0 5px rgba($black, 0.2);

        img {
            width: 54px;
            margin: 12px auto 0;
        }
    }

    .front {
        &:after {
            @include smallCoinSize;
        }
    }

    .back {
        &:after {
            @include smallCoinSize;
        }
        img {
            margin-top: 9px;
        }
    }
}

@media only screen and (min-width: 480px) and (max-width: 568px) and (max-height: 400px) and (orientation: landscape) {
    $coin-size: 60px;
    $half-coin-size: 30px;

    @mixin smallCoinSize {
        @include coinSize($coin-size);
    }

    .coin {
        @include smallCoinSize;
        margin: 10px 5px;
    }

    .flipper {
        transform-origin: 100% $half-coin-size;
    }

    .front, .back {
        @include smallCoinSize;
        box-shadow: inset 0 0 0 4px #FED477, inset 0 0 0 5px rgba($black, 0.1), 0 0 4px rgba($black, 0.2);
        img {
            width: 42px;
            margin: 10px auto 0;
        }
    }

    .front {
        &:after {
            @include smallCoinSize;
        }
    }

    .back {
        &:after {
            @include smallCoinSize;
        }
        img {
            margin-top: 8px;
        }
    }
}


@media only screen and (min-width: 320px) and (max-width: 400px) and (orientation: portrait) {
    .coins {
        margin: 0 auto;
    }
}

@media only screen and (max-width: 320px) and (max-height: 480px) and (orientation: portrait) {
    $coin-size: 60px;
    $half-coin-size: 30px;

    @mixin smallCoinSize {
        @include coinSize($coin-size);
    }

    .coin {
        @include smallCoinSize;
        margin: 10px 5px;
    }

    .flipper {
        transform-origin: 100% $half-coin-size;
    }

    .front, .back {
        @include smallCoinSize;
        box-shadow: inset 0 0 0 4px #FED477, inset 0 0 0 5px rgba($black, 0.1), 0 0 4px rgba($black, 0.2);

        img {
            width: 42px;
            margin: 10px auto 0;
        }
    }


    .front {
        &:after {
            @include smallCoinSize;
        }
    }

    .back {
        &:after {
            @include smallCoinSize;
        }

        img {
            margin-top: 8px;
        }
    }
}

.banner {
    position: absolute;
    width: 100%;
    text-align: center;
    background-color: $teal;
    z-index: 9999;
    height: 0;
    transition: all .3s ease-in;
    overflow: hidden;

    &.show {
        height: 65px;
    }

    &.banner-enter {
        height: 0;
    }

    &.banner-enter-active {
        height: 65px;
    }

    .banner-msg {
        padding: 20px;
    }

    .close-btn {
        position: absolute;
        padding: 0;
        right: 0;
        display: inline-block;
        transform: translate(-50%,-50%);
        top: 50%;
        right: 18px;
        background-color: transparent;
    }
}
.home {
    @media only screen and (min-width: 600px) {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media only screen and (orientation: landscape) and (max-width: 830px) {
        align-items: start;
    }

    h1 {
        text-align: left;
    }

    .content {
        display: flex;
        flex-direction: column-reverse;
        padding: 60px 30px 30px;
        max-width: 1180px;

        .image-content {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        @media only screen and (min-width: 600px) {
            padding: 60px;
        }

        @media only screen and (min-width: 801px) {
            align-content: center;
            flex-direction: row;

            .text-content {
                flex: 2;
            }

            .image-content {
                flex: 1;
            }
        }
    }

    p {
        margin: 2em 0 3em;
    }

    a {
        text-decoration: none;
    }

    button {
        display: block;
        width: 100%;

        a {
            color: $white;
            text-decoration: none;;
        }

        @media only screen and (min-width: 600px) {
            width: auto;
            margin: 0 auto;
        }

        @media only screen and (min-width: 801px) {
            margin: 0;
        }
    }

    @include staticCoin(150px, 'front');

    @media only screen and (min-width: 600px) {
        @include staticCoin(200px, 'front');
    }

    @media only screen and (min-width: 801px) {
        @include staticCoin(300px, 'front');
    }
}
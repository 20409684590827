.about {
    padding: 0 30px 30px;
    -webkit-overflow-scrolling: touch;

    h2 {
        text-align: center;
        margin: 42px 0;
        text-transform: uppercase;
    }

    h4 {
        font-weight: 200;
        text-transform: uppercase;
    }

    .caption {
        font-style: italic;
        display: table-caption;
        margin-top: 20px;
    }

    .example {
        text-align: center;
        width: 100%;
        display: table;
        caption-side: bottom;
        border-spacing: 0 30px;
        border-collapse: separate;

        &.trigrams {
            .static-hexagram {
                width: 25%;
                margin-bottom: 20px;
            }

            .caption {
                margin-top: 0;
            }
        }

        &.divination {
            .coins-row {
                width: 100%;
                margin: 0;

                li {
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;

                    .line-label {
                        width: 15%;
                    }
                }
            }
        }

        .example-row {
            display: flex;
            justify-content: space-evenly;
            flex-wrap: wrap;
            align-items: flex-start;
        }

        .hexagram {
            margin: 0;
            width: 300px;

            li {
                height: 37px;
                margin: 0;

                &:before, &:after {
                    height: 37px;
                }

                span {
                    top: 4px;
                    width: 30px;
                    height: 30px;
                    left: -43px;
                }
            }
        }

        .static-hexagram {
            padding: 0 25px;
            text-align: center;
            font-weight: 800;
            cursor: pointer;

            .hex-caption {
                font-weight: 200;
                font-style: italic;
            }

            .hexagram {
                width: 100%;
                max-width: 200px;
                min-width: 200px;
                margin: 0 auto 10px;

                li {
                    transition-property: none;
                    height: 20px;
                    margin: 10px 0;

                    &:before, &:after {
                        height: 20px;
                    }

                    span {
                        height: 20px;
                        width: 20px;
                        top: 0;
                        left: -35px;
                    }
                }
            }
        }

        .changes-into {
            align-self: center;
        }

        @mixin aboutCoinSize($coinSize) {
            width: $coinSize;
            height: $coinSize;
        }

        .aboutCoinSize {
            @include coinSize(82px);
        }

        .coin {
            @extend .aboutCoinSize;

            .flipper, .back, .front, .back:after, .front:after {
                @extend .aboutCoinSize;
            }

            .back img {
                width: 56px;
                margin-top: 10px;
            }

            .front img {
                width: 56px;
                margin-top: 15px;
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .about .example.trigrams .static-hexagram {
        width: 50%;
    }

    .about .coins-row li {
        flex-direction: column;
        margin-bottom: 50px;

        .line-label {
            font-size: 22px;
            width: 100% !important;
        }

        > div, > ul {
            padding: 20px 0;
        }
    }
}

@media only screen and (max-width: 768px) {
    .about .example.changing .example-row {
        flex-direction: column;
        align-items: center;

        > div {
            margin-bottom: 40px;
        }
    }
}

@media only screen and (max-width: 619px) {
    .about .example.trigrams .static-hexagram {
        width: 100%;
    }
}

@media only screen and (max-width: 480px) {
    .aboutCoinSizeSmall {
        @include coinSize(61px);
    }

    .example.divination {
        .hexagram {
            width: 220px;
        }

        .coin {
            @extend .aboutCoinSizeSmall;

            .flipper, .back, .front, .back:after, .front:after {
                @extend .aboutCoinSizeSmall;
            }

            .back img {
                width: 42px;
                margin-top: 8px;
            }

            .front img {
                width: 42px;
                margin-top: 11px;
            }
        }
    }


}
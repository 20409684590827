.share-buttons {
    display: flex;

    .share-btn {
        transition: width .4s ease-in-out;
        transform-origin: right;
        width: 64px;
        border-radius: 50px;

        &.share-open {
            padding: 0;
            background-color: $teal;
            letter-spacing: 4px;
            width: 164px;
            height: 64px;

            .social-buttons {
                opacity: 1;
                width: auto;
                height: auto;

                a.twitter-btn, a.facebook-btn {
                    padding: 10px;
                    margin: 0 10px;
                }

                a, a svg {
                    width: auto;
                    height: auto;
                }
            }
        }
    }

    .social-buttons {
        transition: opacity .4s ease-in-out;
        transition-delay: .5s;
        opacity: 0;
        height: 0;
        width: 0;

        // We have to diminish all padding and margin
        // so the icons are not clickable since the animation
        // will not work with display block
        a.twitter-btn, a.facebook-btn {
            padding: 0;
            margin: 0;
        }

        a, a svg {
            width: 0;
            height: 0;
        }
    }

    .social-buttons.share-btn-enter-active {
        opacity: 1;
    }

    .social-buttons.share-btn-exit {
        transition: all 0s ease-in-out;
    }

    .social-buttons {
        .facebook-btn, .twitter-btn {
            padding: 10px;
            margin: 0 10px;
            border-radius: 50px;
            border: 2px solid $white;
            line-height: 1;
            display: inline-block;

            transition: background .3s ease-in-out;

            &:active {
                background-color: $green;
            }
        }
    }
}

.reading .share-buttons {
    display: block;

    button {
        margin: 30px auto;
        min-width: 196px;
        display: block;
        height: 64px;
        padding: 15px 60px;
        letter-spacing: 4px;
    }
}

.social-blocked-modal {
    h2 {
        color: $red-orange;
        text-align: center;
        max-width: 70%;
        margin: 30px auto;
    }

    .close-btn {
        padding: 0;
        background: none;
    }

    > svg {
        fill: $red-orange;
        height: 75px;
        width: 75px;
        display: block;
        margin: 0 auto;

        path {
            fill: $red-orange;
        }
    }
}

@media only screen and (min-width: 961px) {
    .reading .share-buttons {
        display: none;
    }
}

@media only screen and (max-width: 480px) {
    .social-blocked-modal {
        > svg {
            height: 50px;
            width: 50px;
        }

        h2 {
            max-width: 80%;
        }
    }
}
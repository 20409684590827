@mixin animatedFor($time) {
    animation-duration: $time;
    animation-fill-mode: both;
}

@keyframes backgroundColors {
    0% {
        background-color: $blue;
    }
    20% {
        background-color: $purple;
    }
    40% {
        background-color: $red-orange;
    }
    60% {
        background-color: $light-blue;
    }
    80% {
        background-color: $green;
    }
    100% {
        background-color: $blue;
    }
}

.backgroundColors {
    animation-name: backgroundColors;
}

@keyframes slideInRight {
    0% {
        transform: translate3d(100%, 0, 0);
        visibility: visible;
    }
        
    100% {
        transform: translate3d(0, 0, 0);
        visibility: visible;
    }
}

@mixin slideInRight {
    animation-name: slideInRight;
}

.slideInRight {
    @include slideInRight;
}

@keyframes slideOutRight {
    0% {
        transform: translate3d(0, 0, 0);
        visibility: visible 
    } 

    100% {
        transform: translate3d(100%, 0, 0);
        visibility: hidden;
    }
}

@mixin slideOutRight {
    animation-name: slideOutRight;
}

.slideOutRight {
    @include slideOutRight;
}

@keyframes moveShine {
    0% {
        background-position:  -60px -100px;
    }
    40% {
        background-position: 100px 20px;
    }
    100% {
        background-position: 100px 20px;
    }
}

@keyframes flipping {
    0% {
        transform: rotateX(0);
    }
    25% {
        transform: rotateX(-180deg);
        top: -50px;
    }
    50% {
        transform: rotateX(0);
    }
    75% {
        transform: rotateX(-180deg);
    }
    100% {
        transform: rotateX(0);
        top: 0;
    }
}

@mixin flipping {
    animation-name: flipping;
}

.flipping {
    @include flipping;
}

@keyframes fadeInUpBig {
    0% {
        opacity: 0;
        transform: translate3d(0, 2000px, 0);
    }
    100% {
        opacity: 1;
        transform: none;
    }
}

@mixin fadeInUpBig {
    animation-name: fadeInUpBig;
}

.fadeInUpBig {
    @include fadeInUpBig;
}

@keyframes coinDrop {
    0%, 40%, 45%, 50%, 55%, 60%, 70%, 80%, 90%, 100% {
        animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }
    0% {
        opacity: 0;
        transform: translate3d(0, -3000px, 0);
    }
    40% {
        opacity: 1;
        transform: translate3d(0, 25px, 0) rotateX(0);
    }
    45% {
        transform: translate3d(0, -50px, 0) rotateX(-180deg);
    }
    50% {
        transform: translate3d(0, 5px, 0) rotateX(0);
    }
    55% {
        transform: rotateX(-180deg);
    }
    60% {
        transform: translate3d(0, -25px, 0);
    }
    70% {
        transform: translate3d(0, 5px, 0);
    }
    80% {
        transform: translate3d(0, -10px, 0);
    }
    90% {
        transform: translate3d(0, 2px, 0);
    }
    100% {
        transform: none;
        opacity: 1;
    }
}

.coinDrop {
    animation-name: coinDrop;
}

@keyframes bounce {
    20% {
        transform: translateY(-10px);
    }
    40% {
        transform: translateY(0);
    }
    60% {
        transform: translateY(0);
    }
    80% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0);
    }
}

@mixin bounce {
    animation-name: bounce;
}

.bounce {
    @include bounce;
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translate3d(0, -2000px, 0);
    }

    100% {
        opacity: 1;
        transform: none;
    }
}

@mixin fadeInDown {
    animation-name: fadeInDown;
}

.fadeInDown {
    @include fadeInDown;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@mixin fadeIn {
    animation-name: fadeIn;
}

.fadeIn {
    @include fadeIn;
}

$reading-gutter: 60px;
$small-hex-line-border-radius: 2px;
// $reading-open-delay: 1.5s;

.reading {
    position: fixed;
    top: 0;
    z-index: 3;
    height: 100vh;
    width: 50%;
    background-color: rgba($white,0.1);
    color: $white;
    color: rgba($white,0.9);
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    -webkit-backface-visibility: hidden;
    text-align: left;
    overflow-x: hidden;
    transform: translateX(205%);

    &.reading-open {
        transform: translateX(100%);
    }

    .reading-innerwrap {
        position: relative;
    }

    .close-btn {
        $offset: $reading-gutter / 2;
        background: none;
        padding: 0;
        color: $white;
        color: rgba($white,0.9);
        position: absolute;
        top: $offset;
        right: $offset;
        transform: translate(-50,-50);
        border-radius: 0;
    }

    .save-btn {
        display: none;
        min-width: 196px;
    }

    // React Transition Classes
    &.reading-enter {
         transform: translateX(204%);
    }

    &.reading-enter.reading-enter-active {
        transition: all 0.5s;
        @extend .reading-open;
    }

    &.reading-exit {
        @extend .reading-open;
    }

    &.reading-exit.reading-exit-active {
        transition: all 0.5s;
        @extend .reading-enter;
    }


}

.static-reading {
    .reading {
        width: 100%;
        left: 0;

        &.reading-open {
            transform: translateX(0);
        }
    }
}

.reading-content {
    line-height: 1.5;
    padding: $reading-gutter;

    .intro {
        text-align: center;

        .hexagram-row {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .trigram-translation {
            text-align: left;
            height: 120px;

            div {
                display: table-row;
                height: 60px;

                > * {
                    display: table-cell;
                    padding: 5px 10px;
                    vertical-align: middle;
                }
            }
        }
    }

    .static-hexagram {
        width: 12%;
        min-width: 90px;
        margin-right: 20px;

        .hexagram {
            width: 100%;
            max-width: 120px;
            margin: 0 auto;

            li {
                transition-property: none;
                height: 8px;
                margin: 8px 0;
                border-radius: $small-hex-line-border-radius;

                &:before, &:after {
                    height: 8px;
                    border-radius: $small-hex-line-border-radius;
                }

                &:nth-child(3) {
                    margin-bottom: 24px;
                }

                span {
                    height: 8px;
                    width: 8px;
                    left: -20px;
                    top: 0;
                }
            }
        }
    }

    .changing-hex {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-top: 60px;

        .static-hexagram {
            margin: 0 50px;
            cursor: pointer;

            .hexagram li {
                margin-bottom: 8px;
            }
        }

        .changes {
            font-style: italic;
        }
    }

    .reading-footer {
        margin: 30px 0 0;
        padding: 10px 0 0;
        border-top: 1px solid rgba($white, 0.9);
    }
}

.all-hexagrams {
    .reading {
        width: 100%;
        opacity: 0;
        background-color: $blue;
        transition: all 0.5s ease;

        &.reading-open {
            left: 0;
            opacity: 1;
        }
    }
}

@media only screen and (max-width: 960px) {
    .reading {
        width: 100%;
        background-color: $blue;
        opacity: 0;
        transition: all 0.5s ease 1s;
        position: absolute;
        height: 100%;

        .save-btn {
            display: block;
            margin: 30px auto;
        }

        &.reading-open {
            transform: translateX(0);
            opacity: 1;
        }
    }
}

@media only screen and (max-width: 480px) {
    .reading-content {
        .changing-hex {
            .static-hexagram {
                margin: 0 35px;
            }
        }

        .static-hexagram {
            margin-right: 10px;
            min-width: 75px;
        }

        .intro .trigram-translation {
            height: auto;
        }
    }
}

@media only screen and (max-width: 360px) {
    .reading-content {
        .changing-hex {
            .static-hexagram {
                margin: 0 17px;
            }

            .changes svg {
                height: 24px;
                width: 24px;
            }
        }
    }
}

@media only screen and (max-width: 500px) {
    .reading-content {
        padding: 30px;
    }

    blockquote {
      -webkit-margin-start: 15px;
      -webkit-margin-end: 15px;
    }
}

@media only screen and (min-width: 961px) and (max-width: 1280px) {

    .reading-content {
        .intro {
            .trigram-translation {
                font-size: 14px;
            }
        }

        .static-hexagram {
            width: 10%;

            .hexagram {
                max-width: 150px;

                li {
                    margin: 6px 0;
                }
            }
        }
    }


}

// Position and sizing of burger button
.menu-toggle-btn {
    position: fixed;
    width: 36px;
    height: 36px;
    right: 36px;
    top: 36px;
    opacity: 1;
    transition: 0.75 all ease-in;
    z-index: 5;

    &.hidden {
        opacity: 0;
        height: 0;
        z-index: -1 !important;
    }

    @media only screen and (max-width: 768px) {
        top: 10px;
        right: 16px;
    }
}


// Position and sizing of close button
.menu-close-btn {
    height: 17px;
    width: 17px;
    position: absolute;
    top: 17px;
    right: 17px;

    @media only screen and (max-width: 768px) {
        top: 10px;
        right: 10px;
    }
}

.menu-toggle-btn,
.menu-close-btn {
    span {
        display: none;
    }

    // override general button styles
    padding: 0;
    border-radius: 0;
    background-color: transparent;
    &:active {
        background-color: transparent;
        padding: 0;
    }
}

// General sidebar styles
.menu {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    height: 100%;
    width: 300px;
    background: $black;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    transition: all 0.25s ease-in;
    transform: translateX(-100%);
    opacity: 0.5;

    overflow: hidden;

    color: #b8b7ad;

    a {
        color: $white;
        text-decoration: none;
        margin: 10px 0;
        display: block;
        padding: 0.5em;
    }

    &.menu-open {
        transform: translateX(0);
        opacity: 1;
    }
}


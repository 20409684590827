$red-orange: #FF4A4A;
$orange: #F4683A;
$gold: #F5D085;
$teal: #21C1AA;
$green: #298561;
$light-blue: #348290;
$blue: #335157;
$purple: #51375E;
$pink: #EC6CB2;
$white: #FDF1F1;
$black: #251A24;

.bg-red-orange {
    background-color: $red-orange;
}

.bg-orange {
    background-color: $orange;
}

.bg-gold {
    background-color: $gold;
}

.bg-teal {
    background-color: $teal;
}

.bg-light-blue {
    background-color: $light-blue;
}

.bg-blue {
    background-color: $blue;
}

.bg-purple {
    background-color: $purple;
}

.bg-pink {
    background-color: $pink;
}

.missing-route-wrapper {
    display: flex;
    align-items: center;
}

.missing-route {
    text-align: center;
    width: 100%;
    padding: 0 40px 40px;

    @media only screen and (max-width: 680px) {
        padding: 0 20px 20px;
    }

    h1 {
        font-size: 450px;
        text-shadow: 1px 1px 1px rgba($black, 0.5);

        @media only screen and (max-width: 960px) {
            font-size: 300px;
        }

        @media only screen and (max-width: 680px) {
            font-size: 200px;
        }

        @media only screen and (max-width: 435px) {
            font-size: 120px;
        }
    }

    a {
        color: $teal;
    }

    @include staticCoin(300px, 'back');

    @media only screen and (max-width: 960px) {
        @include staticCoin(200px, 'back');
    }

    @media only screen and (max-width: 680px) {
        @include staticCoin(125px, 'back');
    }

    @media only screen and (max-width: 435px) {
        @include staticCoin(75px, 'back');
    }
}
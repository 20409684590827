* {
    box-sizing: border-box;
    // Fixes issue in Safari, where font is lighter
    -webkit-font-smoothing: subpixel-antialiased;
}

html {
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 100%;
}

body {
    flex: 1 1 auto;
    margin: 0;
    overflow: hidden;
    min-height: 100%;
}

noscript {
    color: red;
    display: block;
    padding: 25px;
    text-transform: uppercase;
    text-align: center;
}

.page-transition-enter {
    transition: all .5s;
    transform: translateX(100%);
    z-index: 999;
}

.page-transition-enter-active {
    transition: all .5s;
    transform: translateX(0);
    overflow-y: hidden !important;
    backface-visibility: hidden;
}

.page-transition-exit {
    // Negative z-index is important to prevent flickering of back page
    z-index: -1;
}

.app-content {
    > div {
        position: relative;
        width: 100%;
    }
}

.wrapper {
    position: absolute;
    height: 100vh;
    width: 100%;
    // Scrollbars not calculated in width for webkit browsers, defaults to auto everywhere else
    overflow-y: overlay;
    overflow-x: hidden;
    background-image: linear-gradient(to right, rgba(0,0,0,0.1) 50%, transparent 50%);
    @extend .backgroundColors;
    animation-iteration-count: infinite;
    @include animatedFor(150s);
    will-change: transform;
}

.oracle {
    display: block;
    text-align: center;
    margin: 0 auto;
    position: relative;
    min-height: calc(100% - 80px);
    left: 0;
    transition: all 0.5s;
    &.reading-open {
        left: -25%;
        width: 50%;
    }
}

i-reading {
    display: block;
}

i-coins, .hexagram-wrapper {
    display: none;
}

.hexagram-wrapper.show,
i-coins.show {
    display: block;
}

.main-title {
    font-size: $font-size-large;
    text-transform: uppercase;
    display: none;
}

@media only screen and (max-width: 960px) {
    .oracle {
        &.reading-open {
            left: 0;
            width: 100%;
        }
    }
}



@media only screen and (min-width: 480px) and (max-width: 800px) and (orientation: landscape) {
    .oracle {
        top: 50%;
        transform: translateY(-50%);
    }

    i-coins.show, .hexagram-wrapper.show {
        display: inline-block;
        width: 50%;
        float: left;
    }
}

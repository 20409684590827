$line-border-radius: 4px;
$hex-line-height: 50px;

.hexagram-wrapper {
    position: relative;
}

.hexagram {
    width: 450px;
    margin: 30px auto;

    li {
        margin: 20px 0;
        width: 100%;
        height: $hex-line-height;
        position: relative;
        background-color: transparent;
        border-radius: $line-border-radius;
        transition: transform .5s ease-in-out;

        span {
            display: inline-block;
            position: absolute;
            top: 5px;
            left: -($hex-line-height + 10px);
            height: ($hex-line-height - 10px);
            width: ($hex-line-height - 10px);
            border-radius: 50%;
            background-color: transparent;
        }

        &:nth-of-type(odd) {
            transform: translateX(-7000px);
        }
        &:nth-of-type(even) {
            transform: translateX(7000px);
        }

        &:after,
        &:before {
            content: '';
            height: $hex-line-height;
            width: 45%;
            display: block;
            position: absolute;
            background-color: transparent;
            border-radius: $line-border-radius;
            transition: all .5s ease-in-out;
        }

        &:after {
            left: 0;
        }

        &:before {
            right: 0;
        }

        &.solid, &.broken{
            transform: translateX(0);
        }

        &.solid {
            background-color: $black;
        }

        &.broken {
            &:after, &:before {
                background-color: $black;
            }
        }

        &.changing {
            span {
                background-color: $black;
            }
        }
    }
}

.hex-description {
    @extend .fadeInUpBig;
    @include animatedFor(0.5s);
    position: absolute;
    top: calc(100% + 30px);
    left: 0;
    width: 100%;

    button {
        margin: 10px 10px 0;
    }

    .buttons {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.hex-num {
    margin: 6px auto 24px;
}

.hex-name {
    font-size: $font-size-medium;
}

.reset-btn {
    margin-top: 10px;
}

.down-arrow {
    display: none;
}

.all-hexagrams {
    -webkit-overflow-scrolling: touch;

    .hexagrams-list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;

        > li {
            width: 25%;
            display: inline-block;
            cursor: pointer;
            padding: 60px 0;

            @media only screen and (max-width: 960px) {
                width: 50%;
            }

            @media only screen and (max-width: 500px) {
                width: 100%;
                padding: 30px 0;
            }
        }

        &.reading-open {
            max-height: 100vh;
            overflow: hidden;
            margin: 0;
        }

        .static-hexagram {
            padding: 0 25px;
            text-align: center;
            font-weight: 800;

            .hexagram {
                width: 100%;
                max-width: 200px;
                margin: 0 auto 10px;

                li {
                    transition-property: none;
                    height: 20px;
                    margin: 10px 0;

                    &:before, &:after {
                        height: 20px;
                    }
                }
            }
        }
    }

    .reading-content {
        .static-hexagram {
            max-width: 10%;
        }
    }
}



@media only screen and (max-width: 1150px) {
    .hexagram {
        width: 400px;
    }
}

@media only screen and (max-width: 1024px) {
    .hexagram {
        width: 350px;
    }
}

@media only screen and (min-width: 961px) {
    .hex-description {
        //max-width: 50%;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 960px) {
    .down-arrow {
        display: block;
        color: $white;
        text-decoration: none;
        margin: 25px 0 0;
        @include bounce;
        @include animatedFor(4s);
        animation-iteration-count: infinite;
        height: 60px;
        span {
            display: inline-block;
            margin-top: 25px;
        }
    }
}

@media only screen and (max-width: 500px) {
    $hex-line-height: 30px;

    .hexagram {
        width: calc(100% - 120px);

        li {
            height: $hex-line-height;

            span {
                top: 2px;
                left: -($hex-line-height + 5px);
                height: ($hex-line-height - 5px);
                width: ($hex-line-height - 5px);
            }

            &:after, &:before {
                height: $hex-line-height;
            }
        }
    }

    .hex-name {
        font-size: $font-size-medium-mobile;
    }
}

@media only screen and (max-width: 360px) {
    .hexagram {
        width: calc(100% - 90px);
    }
}

@media only screen and (max-width: 1024px) and (orientation: landscape) {
    .hexagram li {
        margin: 10px 0;
    }
}

@media only screen and (min-width: 480px) and (max-width: 800px) and (max-height: 500px) and (orientation: landscape) {
    $hex-line-height: 30px;

    .hexagram {
        width: calc(100% - 80px);
        margin: 0 auto;
        position: relative;

        li {
            height: $hex-line-height;
            margin: 10px 0;

            span {
                top: 2px;
                left: -($hex-line-height + 5px);
                height: ($hex-line-height - 5px);
                width: ($hex-line-height - 5px);
            }

            &:after, &:before {
                height: $hex-line-height;
            }
        }
    }

    .hex-description {
        position: absolute;
        top: 54px;
        left: 50%;
        width: 50%;

        > * {
            max-width: calc(100% - 40px);
            margin-left: auto;
            margin-right: auto;
        }
    }
}

@media only screen and (min-width: 480px) and (max-width: 640px) and (max-height: 400px) and (orientation: landscape) {
    $hex-line-height: 20px;

    .hexagram li {
        height: $hex-line-height;

        span {
            left: -($hex-line-height + 5px);
            height: ($hex-line-height - 5px);
            width: ($hex-line-height - 5px);
        }

        &:after, &:before {
            height: $hex-line-height;
        }
    }

    .hex-name {
        font-size: font-size-medium-mobile;
    }
}

@media only screen and (min-width: 320px) and (max-width: 400px) and (orientation: portrait) {
    .hexagram {
        margin: 20px auto 10px;
        li {
            margin: 10px 0;
        }
    }
}

@media only screen and (max-width: 320px) {
    .hex-description {
        .hex-num, .hex-name {
            margin: 20px auto;
        }
        .reset-btn {
            margin-top: 0;
        }
    }
}

@media only screen and (max-width: 320px) and (max-height: 480px) and (orientation: portrait) {
    $hex-line-height: 20px;

    .hexagram li {
        height: $hex-line-height;

        span {
            left: -($hex-line-height + 5px);
            height: ($hex-line-height - 5px);
            width: ($hex-line-height - 5px);
        }

        &:after, &:before {
            height: $hex-line-height;
        }
    }
}

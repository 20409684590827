$font-family-decorative: 'sofia-pro', sans-serif;
$font-family: 'sofia-pro', sans-serif;
$font-size-small: 18px;
$font-size-medium: 24px;
$font-size-large: 36px;
$font-size-small-mobile: 16px;
$font-size-medium-mobile: 18px;
$font-size-large-mobile: 24px;
$font-weight-normal: 100;
$font-weight-bold: 300;
$font-weight-extrabold: 400;

.font-family-decorative {
    font-family: $font-family-decorative;
}

.font-family-default {
    font-family: $font-family;
}

body {
    color: $white;
    font-family: $font-family;
    font-size: $font-size-small;
    font-weight: $font-weight-normal;
    letter-spacing: 0.025em;
}

h1, .h1, h2, .h2 {
    font-weight: $font-weight-bold;
    line-height: 1;
}

h1, .h1 {
    font-family: $font-family-decorative;
    font-weight: $font-weight-extrabold;
    text-transform: uppercase;
    letter-spacing: 3px;
    text-align: center;
    font-size: $font-size-large;
    margin: $font-size-medium auto;
}

h2, .h2 {
    font-size: $font-size-medium;
    letter-spacing: 1px;
}

h3, .h3 {
    font-size: 20px;
    letter-spacing: 1px;
    font-weight: $font-weight-bold;
}

h4, .h4 {
    font-size: $font-size-small;
    letter-spacing: 1px;
    font-weight: $font-weight-bold;
}

strong {
    font-weight: $font-weight-extrabold;
}

small {
    font-size: 13px;
    line-height: 1.5;
}

ul {
    list-style: none;
    padding: 0;
}

button {
    background-color: $teal;
    color: $white;
    font-family: $font-family;
    font-size: $font-size-small;
    letter-spacing: 4px;
    text-transform: uppercase;
    padding: 15px 60px;
    border: 0;
    border-radius: 30px;
    transition: all 0.25s ease-in-out;
    cursor: pointer;

    &:active {
        background-color: $green;
        padding: 16px 80px;
        letter-spacing: 7px;
    }

    &:focus, &:active {
        outline: none;
    }

    &.default {
        background-color: transparent;
        border: 2px solid $white;
        padding: 14px 59px;
    }

    &.icon-btn {
        padding: 15px;
        border-radius: 50%;
        line-height: 1;
    }
}

.error-message {
    text-align: center;
    background-color: transparent;
    position: relative;
    height: 100%;
    width: 100%;
    left: 0;
    z-index: 50;

    > * {
        padding: 0 20px;
    }

    img {
        margin-top: 50px;
        width: 25%;
        max-width: 200px;
        max-height: 200px;
    }
}

@media only screen and (max-width: 600px) {
    body {
        font-size: $font-size-small-mobile;
    }

    h1, .h1 {
        font-size: $font-size-large-mobile;
        letter-spacing: 2px;
    }

    h2, .h2 {
        font-size: $font-size-medium-mobile;
    }

    button {
        font-size: $font-size-medium-mobile;
        padding: 12px 40px 13px;
    }

    small {
        font-size: 11px;
    }
}

@media only screen and (max-width: 700px) and (orientation: landscape) {
    .error-message {
        img {
            width: 100px;
        }
    }
}

$input-width: 400px;

.question-container {
    position: absolute;
    left: 0;
    top: 25%;
    z-index: 2;
    width: 100%;
    @extend .fadeInDown;
    @include animatedFor(0.5s);
    animation-delay: 0.5s;

    h2 {
        margin-top: 0;
        font-size: 48px;
        letter-spacing: 3px;
    }

    form {
        margin-top: 20px;
    }

    input {
        padding: 20px 25px;
        border-radius: 15px;
        border-style: none;
        @extend .font-family-default;
        font-size: $font-size-small;
        font-weight: $font-weight-normal;
        letter-spacing: 1px;
        width: $input-width;
        display: block;
        margin: 40px auto;
        background-color: rgba(0,0,0,0.15);
        outline: 0;
        color: $white;

        &:focus {
            outline: 0;
        }
    }

    ::-webkit-input-placeholder {
        color: rgba(250,250,250,0.25);
        letter-spacing: 2px;
    }

    &.hide-question-input {
        display: none;
    }

    span.error-message {
        display: block;
        text-align: left;
        transition: all .5s ease-in-out;
        width: $input-width;
        margin: -32px auto 15px;
        padding: 0 20px;
        text-align: left;
        font-style: italic;
        min-height: 16px;
        font-size: 16px;
        line-height: 1;
        left: -10px;

        &.hidden {
            visibility: hidden;
            opacity: 0;
        }
    }
}

.question-title {
    opacity: 0;
    transition: all .25s ease-in-out;
    margin: 40px auto;
    width: 450px;
    word-wrap: break-word;

    &.show-question {
        opacity: 1;
    }
}

@media only screen and (max-width: 500px) {
    $input-width-mobile: calc(100% - 80px);

    .question-container {
        width: 100%;

        h2 {
            width: $input-width-mobile;
            font-size: $font-size-large;
            margin: 0 auto;
        }

        input {
            width: $input-width-mobile;
            padding: 15px 20px;
            font-size: $font-size-small-mobile;
        }

        span.error-message {
            width: $input-width-mobile;
            font-size: 12px;
        }
    }

    .question-title {
        width: $input-width-mobile;
    }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
    .question-container {
        top: -2%;
    }
}

@media only screen and (max-width: 700px) and (orientation: landscape) {
    .question-container {
        top: -9%;
    }
}

@media only screen and (max-width: 640px) and (orientation: landscape) {
    .question-container {
        top: -7%;
    }
}

@media only screen and (min-width: 500px) and (max-width: 568px) and (orientation: landscape) {
    .question-container {
        margin-top: -10px;
    }
}

@media only screen and (min-width: 480px) and (max-width: 800px) and (orientation: landscape) {
    .question-title {
        margin: 0 auto 30px;
    }
}

@media only screen and (max-width: 360px) {
    .question-container {
        top: 20%;
    }

    .question-title {
        margin: 20px auto;
    }
}

@media only screen and (max-width: 600px) and (max-height: 320px) and (orientation: landscape) {
    .question-container {
        top: -15%;
    }
}

@media only screen and (max-width: 480px) and (max-height: 320px) and (orientation: landscape) {
    .question-container {
        top: -20%;

        input {
            margin: 25px auto 20px;
        }

        span.error-message {
            margin: -15px auto 15px;
        }
    }
}

@media only screen and (max-width: 320px) and (max-height: 480px) and (orientation: portrait) {
    .question-container {
        top: 10%;
    }
}
